/* .table > :not(caption) > * > th {
  border-bottom-width: 2px !important;
  border-color: rgb(206, 206, 209);
}
.table > :not(caption) > * > * {
  align-items: center;
} */
.MuiTableHead-root > .MuiTableRow-root > .MuiTableCell-root {
  font-weight: 600;
  opacity: 0.7;
  font-size: 1rem;
}
.MuiTableBody-root > .MuiTableRow-root > .MuiTableCell-root {
  opacity: 0.9;
  font-size: 0.9rem;
}
