.sign-in-img {
  /* max-width: 31rem; */
}

.sign-in-form-container {
  background-color: #4d60fc;
}

.sign-in-card {
  width: 90%;
}

.sign-in-button,
.sign-in-button:hover,
.sign-in-button:active {
  background-color: #4d60fc !important;
}

@media only screen and (max-width: 767px) {
  .sign-in-image-container,
  .sign-in-img {
    display: none;
  }
}
