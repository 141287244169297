.sign-up-img {
  max-width: 31rem;
}

.sign-up-form-container {
  background-color: #4d60fc;
}

.sign-up-card {
  width: 90%;
}

.sign-up-button,
.sign-up-button:hover,
.sign-up-button:active {
  background-color: #4d60fc !important;
}

@media only screen and (max-width: 767px) {
  .sign-up-image-container,
  .sign-up-img {
    display: none;
  }
}
